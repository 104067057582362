<template>
  <div>
    <v-dialog v-model="show_dialog" width="600">
      <v-card>
        <v-card-title class="primary_2 text-h6 white--text py-2">
          Update Status
          <v-spacer></v-spacer>
          <v-btn fab small text @click="closeDialog()">
            <v-icon large color="white">
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-form ref="update_las_status">
            <v-row class="ma-0">
              <v-col cols="6" class="pb-0">
                <div>
                  Customer Name
                </div>
                <div class="font-weight-black">
                  {{ customer_details.full_name }}
                </div>
              </v-col>
              <v-col cols="6" class="pb-0">
                <div>
                  Mobile
                </div>
                <div class="font-weight-black">
                  {{ customer_details.mobile }}
                </div>
              </v-col>
              <v-col cols="12" class="pb-0">
                <div>
                  Email
                </div>
                <div class="font-weight-black">
                  {{ customer_details.email }}
                </div>
              </v-col>
              <v-col cols="6" class="pb-0">
                <div>
                  Status
                </div>
                <v-select
                  outlined
                  placeholder="Select loan status"
                  dense
                  required
                  :items="loan_status_list"
                  v-model="loan_status"
                  :rules="$rules.REQURIED_FILED"
                ></v-select>
              </v-col>
              <v-col cols="6" class="pb-0">
                <div>
                  Updated On
                </div>
                <v-menu
                  v-model="updated_on_menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="updated_on"
                      dense
                      outlined
                      required
                      :rules="$rules.REQURIED_FILED"
                      placeholder="Select date"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="updated_on"
                    @input="updated_on_menu = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" class="pb-0" v-if="loan_status == 'Approved'">
                <div>
                  Applied Loan Amount
                </div>
                <v-text-field
                  outlined
                  :rules="$rules.AMOUNT"
                  placeholder="Enter loan amount"
                  dense
                  required
                  type="number"
                  v-model="applied_amount"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="pb-0" v-if="loan_status == 'Disbursed'">
                <div>
                  Disbursed Loan Amount
                </div>
                <v-text-field
                  outlined
                  placeholder="Enter loan amount"
                  dense
                  :rules="$rules.AMOUNT"
                  required
                  type="number"
                  v-model="disbursed_amount"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex align-center justify-end pb-4">
          <v-btn
            color="secondary_2"
            rounded
            class="mx-1 white--text px-4"
            @click="updateStatus()"
            depressed
            :loading="update_las_status_btn_loader"
            :disabled="update_las_status_btn_loader"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show_dialog: false,
      updated_on_menu: false,
      customer_id: "",
      customer_details: {},
      update_las_status_btn_loader: false,
      disbursed_amount: null,
      applied_amount: null,
      loan_status: null,
      loan_status_list: ["Approved", "Disbursed", "Completed", "Rejected"],
      updated_on: null,
    };
  },
  computed: {},
  methods: {
    openUpdateLasStatus(value) {
      // console.log("openUpdateLasStatus", value);
      this.show_dialog = true;
      this.customer_details = value;
    },

    closeDialog() {
      this.$refs.update_las_status.reset();
      this.show_dialog = false;
    },
    handlePush() {
      if (this.stage == "PC") {
        this.pushLead();
      } else {
        if (this.$refs.update_las_status.validate()) {
          // if (this.loan_amount && this.tenure) {
          this.pushLead();
          // alert("Success")
        } else {
          this.showSnakeBar("error", "Please fill both Loan Amount and Tenure");
        }
      }
    },
    updateStatus() {
      if (this.$refs.update_las_status.validate()) {
        const self = this;
        self.update_las_status_btn_loader = true;
        let form = {};
        form["loan_status"] = this.loan_status;
        form["updated_on"] = this.updated_on;

        if (this.loan_status == "Approved") {
          form["applied_amount"] = this.applied_amount;
        } else if (this.loan_status == "Disbursed") {
          form["disbursed_amount"] = this.disbursed_amount;
        }

        const successHandler = () => {
          this.update_las_status_btn_loader = false;
          this.$refs.update_las_status.reset();
          this.$emit("update");
          this.show_dialog = false;
        };
        const finallyHandler = () => {
          self.update_las_status_btn_loader = false;
        };
        self.POST_request(
          self,
          self.$urls.LAS_UPDATE_STATUS + `${this.customer_details.id}/`,
          form,
          successHandler,
          null,
          null,
          finallyHandler
        );
      }
      // } else {
      //   this.showSnakeBar("error", "Please select a reason before rejecting");
      // }
    },
  },
};
</script>
